import React from "react";
import Helmet from "react-helmet";
import { Container } from "reactstrap";
import { Section, PageHeroSection } from "components/section";
import Layout from "components/Layout";
import Form from "components/form";

const SecondPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Request a free carpet cleaning quote - CleanWright</title>
      </Helmet>
      <PageHeroSection>
        <h1>Request a Free Quote</h1>
      </PageHeroSection>
      <Section>
        <Container>
          <p>
            Cleanwright offer carpet, upholstery and curtain cleaning services
            across Taunton, Wellington and the surrounding area. If you are
            nearby and would like a quote, please fill in the contact form
            below.
          </p>
          <p className="mb-5">Alternatively, call Steve on 01823 666025.</p>
          <div style={{ maxWidth: "800px", margin: "0 auto" }}>
            <Form name="Quote Form" />
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default SecondPage;
