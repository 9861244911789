import React from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import Button from "components/button";

const myForm = (props) => {
  return (
    <Form name={props.name} method="POST" data-netlify="true">
      <input type="hidden" name="form-name" value={props.name} />
      <FormGroup>
        <Label>Your Name</Label>
        <Input required name="Name" type="text" />
      </FormGroup>
      <FormGroup>
        <Label>Email Address</Label>
        <Input required name="Email Address" type="email" />
      </FormGroup>

      <FormGroup>
        <Label>Phone Number</Label>
        <Input required name="Phone Number" type="tel" />
      </FormGroup>

      <FormGroup>
        <Label>Brief Description</Label>
        <Input name="Description" type="textarea" />
      </FormGroup>
      <div className="text-center mt-5">
        <Button type="submit">Get your quote</Button>
      </div>
    </Form>
  );
};
export default myForm;
